import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '../../components/Typography';
import AdbIcon from '@material-ui/icons/Adb';
import TimerIcon from '@material-ui/icons/Timer';
import AssessmentIcon from '@material-ui/icons/Assessment';

const styles = theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.light,
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(30),
    display: 'flex',
    position: 'relative',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  image: {
    height: 55,
    fontSize: 100
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
  },
});

function ProductValues(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <AdbIcon className={classes.image} />
              <Typography variant="h6" className={classes.title}>
                The power of agents
              </Typography>
              <Typography variant="h5" align='center'>
                {'Rely on the computational power of a multi-agent platform. '}
                {'Evaluate your tasksets through a realistic simulation.'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <TimerIcon className={classes.image} />
              <Typography variant="h6" className={classes.title}>
                Real Time
              </Typography>
              <Typography variant="h5" align='center'>
                {'Test your critical systems using the most reliable'}
                {' real time algorithms.'}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={classes.item}>
              <AssessmentIcon className={classes.image} />
              <Typography variant="h6" className={classes.title}>
                Performance
              </Typography>
              <Typography variant="h5" align='center'>
                {'Gather meaningful data on each simulation '}
                {'and tweak your tasks according to the results.'}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}

ProductValues.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProductValues);
